'use client';
import ModuleCard from '@/components/dashboard/ModuleCard';
import { ProGloveDisplayTemplateId, useProGlove } from '@/components/proglove/ProgloveProvider';
import { userPermissionsAtom } from '@/context/atoms/UserPermissions';
import { type Module, useAppModules } from '@/context/modules';
import { Grid } from '@mui/material';
import { useAtom } from 'jotai';
import React, { useEffect, useRef } from 'react';

export default function ModuleList(): React.JSX.Element {
  const [anyNodePermissions] = useAtom(userPermissionsAtom);
  const { getAccessibleModules } = useAppModules();

  const proGloveDisplayUpdate = useRef<boolean>(false);

  const { updateDisplay } = useProGlove();

  useEffect(() => {
    if (!proGloveDisplayUpdate.current) {
      updateDisplay({
        display_template_id: ProGloveDisplayTemplateId.PG1,
        display_fields: [
          {
            display_field_id: 1,
            display_field_header: '',
            display_field_text: 'Select your shopfloor app',
          },
        ],
      });
      proGloveDisplayUpdate.current = true;
    }

    return () => {
      proGloveDisplayUpdate.current = false;
    };
  }, [updateDisplay]);

  return (
    <Grid container spacing={2}>
      {getAccessibleModules(anyNodePermissions).map((m: Module, i: number) => {
        return <ModuleCard appModule={m} timeout={650 * i} key={i} />;
      })}
    </Grid>
  );
}
